import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { useStaticQuery, graphql, Link as RouterLink, navigate } from "gatsby"

import {
  Box,
  Grid,
  Fab,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  Badge,
  Link as StyleLink,
  Snackbar,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@material-ui/icons"

import { VariableSizeList } from "react-window"

import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "!mapbox-gl"

// import ScrollReveal from "../animation/scroll-reveal"

import { Scrollbars } from "react-custom-scrollbars"

import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

import createBbox from "@turf/bbox"

import { transformLocations } from "../transformers/transform-locations"
import { useHeight } from "../functions/use-sizes"

import Button from "../modules/button"

const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

const Map = props => {
  // useEffect(() => {
  //   console.log("=== Map ===", props)
  // })

  const getButtonStyle = () => ({
    color: customTheme.color.text.light,
    backgroundColor: customTheme.color.action.main,
    "&:hover": {
      backgroundColor: customTheme.color.action.dark,
    },
  })

  const useStyles = makeStyles({
    badge: {
      boxShadow: `-4px 4px 0px ${customTheme.color.background.lighten}`,
    },
  })

  const data = useStaticQuery(graphql`
    query {
      strapi {
        environments(sort: "title") {
          id
          title
        }
        taxons(sort: "title") {
          id
          title
        }
        entities(sort: "title") {
          id
          title
          events {
            id
            title
          }
          curatorLocations {
            id
            title
          }
          partnerLocations {
            id
            title
          }
          managerLocations {
            id
            title
          }
          ownerLocations {
            id
            title
          }
        }
        events(sort: "date") {
          id
          title
        }
        programs(sort: "title") {
          id
          title
        }
        municipalities(sort: "title") {
          id
          title
        }
        departments(sort: "title") {
          id
          title
        }
      }
    }
  `)

  // console.log("data.strapi", data.strapi)
  const {
    environments,
    taxons,
    entities,
    events,
    programs,
    municipalities,
    departments,
  } = data.strapi

  // const mapQuerySchema = getMapQuerySchema({
  //   queryBy: "gatsby",
  // })
  // console.log("GraphQL schema :", mapQuerySchema)

  // const result = useStaticQuery(mapQuerySchema)
  // console.log("GraphQL result :", result)

  const { points, bbox } = transformLocations({
    locations: props.locations,
    defaultPages: props.defaultPages,
  })
  // console.log("points", points)

  const [count, setCount] = useState(props.locations.length)

  const organizers = entities.filter(entity => entity.events?.length > 0)
  const curators = entities.filter(
    entity => entity.curatorLocations?.length > 0
  )
  const partners = entities.filter(
    entity => entity.partnerLocations?.length > 0
  )
  const managers = entities.filter(
    entity => entity.managerLocations?.length > 0
  )
  const owners = entities.filter(entity => entity.ownerLocations?.length > 0)

  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)

  const handleZoom = level => e => {
    map.easeTo({
      zoom: map.getZoom() + level,
    })
  }

  const [width, setWidth] = useState(360)

  const toggleFilter = e => {
    setWidth(width > 0 ? 0 : 360)
  }

  const defaultFilter = {
    locations: [],
    environments: [],
    taxons: [],
    open: [],
    events: [],
    programs: [],
    organizers: [],
    curators: [],
    partners: [],
    managers: [],
    owners: [],
    municipalities: [],
    departments: [],
  }

  const [filter, setFilter] = useState(defaultFilter)

  const [openSnacbar, setOpenSnacbar] = useState(false)

  const handleFilter = props => {
    // console.log("handleFilter", props)
    setTimeout(() => {
      setFilter({
        ...filter,
        [props.key]: props.value.map(item => item.id),
      })
    }, 100)
  }

  // START : Filter points
  const filterPoints = () => {
    // console.log("filterPoints")

    if (!map || !props.displayFilter) return null

    let nextPoints = points

    let emptyFilter = true
    Object.keys(filter).forEach(name => {
      if (filter[name].length > 0) {
        emptyFilter = false
      }
    })
    if (!emptyFilter) {
      nextPoints = nextPoints.filter(point => {
        const matchInArray = name =>
          // If "all" is selected for this filter field
          (filter[name].includes("0") && point.properties[name]?.length > 0) ||
          // If specific option is selected
          (!filter[name].includes("0") &&
            filter[name].some(itemId =>
              point.properties[name].includes(itemId)
            ))

        // Match filters
        if (matchInArray("open")) return true
        if (matchInArray("locations")) return true
        if (matchInArray("environments")) return true
        if (matchInArray("taxons")) return true
        if (matchInArray("events")) return true
        if (matchInArray("programs")) return true
        if (matchInArray("organizers")) return true
        if (matchInArray("curators")) return true
        if (matchInArray("partners")) return true
        if (matchInArray("managers")) return true
        if (matchInArray("owners")) return true
        if (matchInArray("municipalities")) return true
        if (matchInArray("departments")) return true

        return false
      })
    }

    const nextCollection = {
      type: "FeatureCollection",
      features: nextPoints,
    }

    map.getSource("pointsSource").setData(nextCollection)

    const nextCount = nextPoints.length

    setCount(nextCount)

    setOpenSnacbar(true)

    nextCount > 0 &&
      map.fitBounds(createBbox(nextCollection), {
        padding: 40,
      })
  }
  // STOP : Filter points

  const popupRef = useRef(new mapboxgl.Popup({ closeButton: false }))

  useEffect(() => {
    console.log("filterPoints", filter)
    filterPoints()
  }, [filter])

  // START : Function useEffect()
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken:
        "pk.eyJ1IjoiYWxnb3VzIiwiYSI6ImNqZWN3dG1zNDBvdXgzM21rdDZwd2pyMTIifQ.0H0XitL8AcIIRUIkDU66uQ",
      attributionControl: false,
      logoPosition: "bottom-right",
      style: "mapbox://styles/mapbox/outdoors-v11",
      zoom: 5,
      maxZoom: 15,
      center: [2.213749, 46.227638],
      // scrollZoom: false,
    })

    map.on("load", () => {
      setMap(map)
      map.resize()

      map.addSource("pointsSource", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: points,
        },
        cluster: true,
        clusterRadius: 40,
      })
      map.addLayer({
        id: "pointsLayer",
        type: "circle",
        source: "pointsSource",
        paint: {
          "circle-radius": [
            "case",
            ["!", ["has", "point_count"]],
            10, // default radius
            [
              "step",
              ["get", "point_count"],
              10,
              2, // step
              11,
              3, // step
              12,
              4, // step
              13,
              5, // step
              14,
              6, // step
              15,
              7, // step
              16,
              8, // step
              17,
              9, // step
              18,
              10, // step
              19,
              // -----------------------------
              15, // step
              20,
              20, // step
              21,
              25, // step
              22,
              30, // step
              23,
              35, // step
              24,
              40, // step
              25,
              45, // step
              26,
              // -----------------------------
              50, // step
              27,
              55, // step
              27.5,
              60, // step
              28,
              65, // step
              28.5,
              70, // step
              29,
              75, // step
              29.5,
              80, // step
              30,
              85, // step
              30.5,
              90, // step
              31,
              95, // step
              31.5,
              100, // step
              32,
              // -----------------------------
              150, // step
              32.5,
              200, // step
              33,
              250, // step
              33.5,
              300, // step
              34,
            ],
          ],
          "circle-color": [
            "case",
            ["!", ["has", "point_count"]],
            customTheme.color.primary.main,
            customTheme.color.secondary.main,
          ],
          "circle-stroke-width": 2,
          "circle-stroke-color": "#FFFFFF",
        },
      })
      map.addLayer({
        id: "labelsLayer",
        type: "symbol",
        source: "pointsSource",
        layout: {
          "text-field": [
            "case",
            ["==", ["get", "point_count"], null],
            "1", // if count = 1
            ["get", "point_count"], // if count > 1
          ],
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 11,
        },
        paint: {
          "text-color": "#FFFFFF",
        },
      })

      // START : Fit to points
      if (bbox) {
        map.fitBounds(bbox, {
          padding: 40,
        })
      }
      // STOP : Fit to points

      map.on("mouseenter", "pointsLayer", e => {
        map.getCanvas().style.cursor = "pointer"
      })
      map.on("mouseleave", "pointsLayer", () => {
        map.getCanvas().style.cursor = ""
      })
      map.on("click", "pointsLayer", e => {
        const properties = e.features[0].properties
        const coordinates = e.features[0].geometry.coordinates.slice()

        setTimeout(() => {
          if (popupRef && properties.title) {
            const popupNode = document.createElement("div")
            ReactDOM.render(
              // <StyleLink
              //   component={RouterLink}
              //   to={properties.url}
              //   style={{ textDecoration: "none" }}
              // >
              <Box
                padding={2}
                textAlign="center"
                onClick={() => navigate(properties.url)}
                style={{ cursor: "pointer" }}
              >
                <Box style={{ color: customTheme.color.primary.main }}>
                  <Typography variant="body2" noWrap>
                    <b>{properties.title}</b>
                  </Typography>
                </Box>
                <Box
                  color="text.secondary"
                  style={{ textTransform: "capitalize" }}
                >
                  <Typography variant="body2" noWrap>
                    {properties.subtitle.toLowerCase()}
                  </Typography>
                </Box>
                <StyleLink component={RouterLink} to={properties.url}>
                  <Typography variant="caption">Découvrir</Typography>
                </StyleLink>
              </Box>,
              // </StyleLink>
              popupNode
            )
            popupRef.current
              .setLngLat(coordinates)
              .setDOMContent(popupNode)
              .addTo(map)
          }
        }, 0)

        // Log on clicked feature
        console.log("=============")
        Object.keys(properties).forEach(key =>
          console.log("===", key, properties[key])
        )
        console.log("=============")

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        if (properties.cluster) {
          map
            .getSource("pointsSource")
            .getClusterExpansionZoom(properties.cluster_id, (err, zoom) => {
              if (err) return
              map.easeTo({
                center: coordinates,
                zoom: zoom,
              })
            })
        }
        // if (properties.title) {
        //   map.easeTo({
        //     center: coordinates,
        //   })
        // }
      })
    })

    setMap(map)

    return () => map.remove()
  }, [])
  // STOP : Function useEffect()

  // ===================================================================================================
  const LISTBOX_PADDING = 8 // px

  function renderRow(props) {
    const { data, index, style } = props
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    })
  }

  const OuterElementContext = React.createContext({})

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
  })

  function useResetCache(data) {
    const ref = React.useRef(null)
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true)
      }
    }, [data])
    return ref
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props
    const itemData = React.Children.toArray(children)
    const itemCount = itemData.length
    const itemSize = 36

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      // return itemData.map(itemSize).reduce((a, b) => a + b, 0)
      return itemData.length * itemSize
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  })
  // ===================================================================================================

  // START : Component < MultiSelect />
  const MultiSelect = props => {
    const [open, setOpen] = useState(false)
    // console.log("open", open)

    const [selected, setSelected] = useState(
      filter[props.name]
        ? props.options.filter(option => filter[props.name].includes(option.id))
        : []
    )
    // console.log("selected", selected)

    return (
      <Badge
        badgeContent={<small>{props.options.length - 1}</small>}
        color="primary"
        max={999}
        classes={{ badge: classes.badge }}
        style={{
          width: "100%",
        }}
      >
        <Autocomplete
          fullWidth
          multiple
          options={props.options}
          disableCloseOnSelect
          disableListWrap
          ListboxComponent={ListboxComponent}
          getOptionLabel={option => option.title}
          renderOption={(option, { selected, inputValue }) => {
            const matches = match(option.title, inputValue)
            const parts = parse(option.title, matches)
            return (
              <Typography variant="body2" noWrap>
                <Checkbox
                  style={{
                    padding: 0,
                    marginRight: 8,
                  }}
                  checked={selected}
                  size="small"
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </Typography>
            )
          }}
          renderInput={params => (
            <TextField {...params} variant="outlined" label={props.label} />
          )}
          value={selected}
          onChange={(e, value) => {
            // console.log("onChange", value)
            setSelected(value)
            if (!open) {
              handleFilter({
                key: props.name,
                value: value,
              })
            }
          }}
          onOpen={() => {
            // console.log("onClose")
            setOpen(true)
          }}
          onClose={() => {
            // console.log("onClose")
            setOpen(false)
            handleFilter({
              key: props.name,
              value: selected,
            })
          }}
        />
      </Badge>
    )
  }
  // STOP : Component < MultiSelect />

  const classes = useStyles()

  const height = useHeight(props.height)

  return (
    <Grid item xs={12}>
      {/* <ScrollReveal> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {props.displayFilter && (
          <Grid item>
            <Box
              height={`calc((100vh - 80px) * ${height})`}
              boxShadow={20}
              zIndex={1}
              position="relative"
              style={{
                width: width,
                transition: "width 0.2s ease-in-out",
                backgroundColor: customTheme.color.background.lighten,
                overflow: "hidden",
              }}
              onTransitionEnd={() => map.resize()}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                height="64px"
                paddingX={2}
                style={{
                  color: customTheme.color.text.light,
                  backgroundColor: customTheme.color.action.main,
                  cursor: "pointer",
                }}
                onClick={toggleFilter}
              >
                <SearchIcon fontSize="small" />
                <Box>
                  <small>{count > 0 ? count : "Aucun"}</small>
                  {" résultat"}
                  {count > 1 && "s"}
                </Box>
                <IconButton style={{ color: "inherit" }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>

              <Scrollbars
                autoHide
                style={{ width: "100%", height: "calc(100% - 64px)" }}
              >
                <Box margin={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MultiSelect
                        name="locations"
                        label="Sites protégés"
                        options={[
                          { id: "0", title: "Tous les sites" },
                          ...props.locations,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="environments"
                        label="Types de milieu"
                        options={[
                          { id: "0", title: "Tous les milieux" },
                          ...environments,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="open"
                        label="Accessibilité"
                        options={[
                          { id: true, title: "Ouvert au public" },
                          { id: false, title: "Fermé au public" },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="municipalities"
                        label="Communes"
                        options={[
                          { id: "0", title: "Toutes les communes" },
                          ...municipalities,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="departments"
                        label="Départements"
                        options={[
                          { id: "0", title: "Tous les départements" },
                          ...departments,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="taxons"
                        label="Espèces"
                        options={[
                          { id: "0", title: "Toutes les espèces" },
                          ...taxons,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="curators"
                        label="Conservateurs"
                        options={[
                          { id: "0", title: "Tous les conservateurs" },
                          ...curators,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="partners"
                        label="Partenaires"
                        options={[
                          { id: "0", title: "Tous les partenaires" },
                          ...partners,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="managers"
                        label="Gestionnaires"
                        options={[
                          { id: "0", title: "Tous les gestionnaires" },
                          ...managers,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="owners"
                        label="Propriétaires"
                        options={[
                          { id: "0", title: "Tous les propriétaires" },
                          ...owners,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="events"
                        label="Animations"
                        options={[
                          { id: "0", title: "Toutes les animations" },
                          ...events,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="programs"
                        label="Programmes"
                        options={[
                          { id: "0", title: "Tous les programmes" },
                          ...programs,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiSelect
                        name="organizers"
                        label="Animateurs"
                        options={[
                          { id: "0", title: "Tous les animateurs" },
                          ...organizers,
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-evenly">
                        <Box onClick={() => setFilter(defaultFilter)}>
                          <Button
                            background="LIGHT"
                            linkLabel="Réinitialiser"
                            type="RESET"
                          />
                        </Box>
                        <Box onClick={toggleFilter}>
                          <Button
                            background="LIGHT"
                            linkLabel="Fermer"
                            type="CLOSE"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Scrollbars>
            </Box>
          </Grid>
        )}

        <Grid item xs>
          <Box
            ref={mapContainer}
            width="100%"
            height={`calc((100vh - 80px) * ${height})`}
            position="relative"
            boxShadow={20}
          >
            <Snackbar
              open={openSnacbar}
              autoHideDuration={6000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              message={
                <Box textAlign="center">{`${
                  count > 0 ? count : "Aucun"
                } résultat${count > 1 ? "s" : ""} trouvé${
                  count > 1 ? "s" : ""
                }`}</Box>
              }
              style={{ position: "absolute" }}
              onClose={() => setOpenSnacbar(false)}
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => setOpenSnacbar(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />

            {props.displayFilter && (
              <Box margin={1} position="absolute" top={0} left={0} zIndex={3}>
                {width === 0 && (
                  <Box margin={1}>
                    <Fab
                      size="small"
                      css={getButtonStyle}
                      onClick={toggleFilter}
                    >
                      <SearchIcon />
                    </Fab>
                  </Box>
                )}
              </Box>
            )}

            <Box margin={1} position="absolute" top={0} right={0} zIndex={3}>
              <Box margin={1}>
                <Fab
                  size="small"
                  css={getButtonStyle}
                  aria-label="zoom in"
                  onClick={handleZoom(+0.5)}
                >
                  <ZoomInIcon />
                </Fab>
              </Box>
              <Box margin={1}>
                <Fab
                  size="small"
                  css={getButtonStyle}
                  aria-label="zoom out"
                  onClick={handleZoom(-0.5)}
                >
                  <ZoomOutIcon />
                </Fab>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </ScrollReveal> */}
    </Grid>
  )
}

export default Map
