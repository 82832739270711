import createBbox from "@turf/bbox"

const controlArray = array => (array?.length > 0 ? array : [])
const flatArray = array => (array ? [...new Set(array.flat())] : [])

export const transformLocations = props => {
  let points = []
  let coordinates = []

  props.locations?.length > 0 &&
    props.locations.forEach(location => {
      // console.log("location", location)
      if (location.longitude && location.latitude) {
        const locationCoordinates = [
          Number(location.longitude),
          Number(location.latitude),
        ]
        // Push points
        points = [
          ...points,
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: locationCoordinates,
            },
            properties: {
              id: location.id,
              title: location.title,
              subtitle: location.subtitle,
              url:
                ((props.defaultPages?.locationsPage?.paths &&
                  props.defaultPages?.locationsPage?.paths[0].slug) ||
                  "/locations") +
                "/" +
                location.slug,

              open: [location.open === null ? false : location.open],
              locations: [location.id],
              environments: controlArray(location.environments).map(
                environment => environment.id
              ),
              taxons: controlArray(location.taxons).map(taxon => taxon.id),
              events: controlArray(location.events).map(event => event.id),
              programs: flatArray(
                controlArray(location.events).map(event =>
                  controlArray(event.programs).map(program => program.id)
                )
              ),
              organizers: flatArray(
                controlArray(location.events).map(event =>
                  controlArray(event.organizers).map(organizer => organizer.id)
                )
              ),
              curators: controlArray(location.curators).map(
                curator => curator.id
              ),
              partners: controlArray(location.partners).map(
                partner => partner.id
              ),
              managers: controlArray(location.managers).map(
                manager => manager.id
              ),
              owners: controlArray(location.owners).map(owner => owner.id),
              municipalities: controlArray(location.municipalities).map(
                municipality => municipality.id
              ),
              departments: flatArray(
                controlArray(location.municipalities).map(
                  municipality => municipality.department.id
                )
              ),
            },
          },
        ]
        // Push coordinates
        coordinates = [...coordinates, locationCoordinates]
      }
    })

  const bbox = createBbox({
    type: "FeatureCollection",
    features: points,
  })

  const data = {
    // points: points.filter(point => point.properties.open === true || point.properties.open === false),
    points: points,
    bbox: bbox,
  }
  return data
}
