import React from "react"

import { Container, Grid, Typography, Box, Hidden } from "@material-ui/core"

import Button from "../modules/button"
import Media from "../modules/media"
import Overlay from "../card/overlay"

import { useHeight } from "../functions/use-sizes"

const Hero = props => {
  // React.useEffect(() => {
  //   console.log("=== Hero ===", props)
  // })

  return (
    <Box
      width={"100%"}
      height={`calc((100vh - 80px) * ${useHeight(props.height)})`}
    >
      <Media {...props}>
        <Overlay {...props}>
          {/* <Box paddingY={10} > */}
          <Container>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              {props.title && (
                <Grid item xs>
                  <Typography
                    variant="h1"
                    style={{
                      // textShadow: "0 0 4px black",
                      fontWeight: "bold",
                    }}
                  >
                    {props.title}
                  </Typography>
                </Grid>
              )}
              {props.subtitle && (
                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      // textShadow: "0 0 4px black",
                      fontWeight: "bold",
                    }}
                  >
                    {props.subtitle}
                  </Typography>
                </Grid>
              )}
              {props.description && (
                <Hidden xsDown>
                  <Grid item xs>
                    <Typography>{props.description}</Typography>
                  </Grid>
                </Hidden>
              )}
              {props.linkLabel && props.url && (
                <Grid item xs>
                  <Button {...props} />
                </Grid>
              )}
            </Grid>
          </Container>
          {/* </Box> */}
        </Overlay>
      </Media>
    </Box>
  )
}

export default Hero
